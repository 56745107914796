<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: Reset Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col w-11/12 sm:w-3/5 md:w-1/2 lg:w-3/4 xl:w-3/5">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col w-full py-8 px-12 lg:p-0 lg:w-1/2 text-center">
              <img v-if="appName ==='FlipPay'" src="@/assets/images/logo/flippay-logo.svg" width="65%" height="auto" alt="Flippay" class="mx-auto"/>
              <img v-else-if="appName==='RelloPay'" src="@/assets/images/logo/rellopay-logo.svg" width="65%" height="auto" alt="Rellopay" class="mx-auto"/>
              <div class="vx-card__title text-center pt-6 lg:hidden">
                <h3 class="mb-4 text-xl lg:text-2xl">Reset Password</h3>
                <p>Please enter your new password.</p>
              </div>
            </div>
            <div class="vx-col w-full md:w-full lg:w-1/2">
              <div class="pt-2 pb-4 sm:pb-6 px-6 sm:px-10 lg:pt-10 lg:pb-12 lg:px-10 login-tabs-container bg-white">
                <div class="vx-card__title mb-12 lg:mb-4 text-center lg:text-left hidden lg:block">
                  <h3 class="mb-4 text-xl lg:text-2xl">Reset Password</h3>
                  <p>Please enter your new password.</p>
                  <vs-alert :active.sync="showDismissibleAlert" closable icon-pack="feather" close-icon="icon-x">{{ message }}</vs-alert>
                </div>
                <form class="pt-4">
                  <label for="password">Password</label>
                  <vs-input
                    type="password"
                    name="password"
                    placeholder=""
                    v-model="user.password"
                    class="w-full mt-1 pr-0" data-vv-validate-on="blur"
                    v-validate="'required|passwordFormat'"
                    ref="password"/>
                  <span class="text-danger text-sm">{{errors.first("password")}}</span>
                  <div class="passwordField mt-8 mb-10">
                    <label for="confirmPassword">Confirm password</label>
                    <vs-input
                      type="password"
                      name="confirmPassword"
                      placeholder=""
                      v-model="user.confirmPassword"
                      class="mt-1 pr-0 w-full" data-vv-validate-on="blur" 
                      v-validate="'required|confirmed:password'"/>
                    <span class="text-danger text-sm">{{errors.first("confirmPassword")}}</span>
                  </div>
                </form>

                <div class="flex flex-wrap justify-between mb-0">
                  <vs-button class="w-full round" size="large" :disabled="!validateForm" @click="resetAdminPassword">Reset</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      user: {
        password: "",
        confirmPassword: "",
        token: this.$route.params.token
      },
      showDismissibleAlert: false,
      message: "",
      isAdmin: true,
      appName: process.env.VUE_APP_NAME ||"FlipPay"
    };
  },
  mounted() {
    if (window.location.href.indexOf("admin") > -1) this.isAdmin = true;
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.user.password != "" &&
        this.user.password == this.user.confirmPassword
      );
    }
  },
  methods: {
    ...mapActions("auth", ["resetPassword"]),
    resetAdminPassword() {
      const payload = {
        userDetails: this.user
      };
      this.$validator.validateAll().then(result => {
        if (result) {
          this.resetPassword(payload)
            .then(response => {
              this.$vs.loading.close();
              this.$router.push("/success");
            })
            .catch(error => {
              this.showDismissibleAlert = true;
              this.message = error.message;
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: error.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger"
              });
            });
        }
      });
    }
  },
  created() {
    this.$validator.extend("passwordFormat", {
      getMessage: () =>
        "Password must contain at least one uppercase, lowercase, digit and 8 characters long",
      validate: value =>
        value.match(
          /^(?=.*[A-Z])(?=.*\d)(?=.*[a-z])[A-Za-z\d@$!%*#?&-.]{8,}$/
        ) !== null
    });
  }
};
</script>
